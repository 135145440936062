/*
  Mixin for applying ellipsis to any element
*/
.category-navigation__item {
  flex: 1;
  max-height: 90px;
  list-style: none;
  position: relative;
}
.category-navigation__item__inner {
  cursor: pointer;
  height: 100%;
  border-bottom: 1px solid #dde4e6;
  border-right: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  line-height: 1.3rem;
  position: relative;
}
.category-navigation__item__inner:not(.category-navigation__item__inner--active):hover {
  border-right-color: #bdc3c7;
}
.category-navigation__item__inner--active {
  border-right-color: #3498db;
}
.category-navigation__item__label {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}
.category-navigation__item svg {
  font-size: 16px;
  display: block;
  margin-bottom: 2px;
  transition: color 150ms ease-in;
  color: #3498db;
}
.category-navigation__item:last-child {
  border-bottom: 0;
  border-top: 1px solid #dde4e6;
}
.category-navigation__item__caret {
  position: absolute;
  top: 35%;
  right: 0;
}
.category-navigation__item__caret > svg {
  color: #95a5a6;
}
.category-navigation__item__indicator {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 1.1rem;
}

.category-navigation__menu {
  min-width: 180px;
}
.category-navigation__menu ul {
  background-color: white;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #ecf0f1;
  margin-left: 10px;
}
.category-navigation__menu .navigation-link__inner {
  border-right: 0;
  border-left: 2px solid transparent;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  padding: 16px 12px;
}
.category-navigation__menu .navigation-link__inner:not(.active):hover {
  border-left-color: #bdc3c7;
}
.category-navigation__menu .navigation-link__inner.active {
  border-left-color: #3498db;
}
.category-navigation__menu .navigation-link__inner > div {
  margin-bottom: 2px;
}
.category-navigation__menu .navigation-link__indicator {
  bottom: unset;
  top: unset;
}
.category-navigation__menu .navigation-link:last-child .navigation-link__inner {
  border-bottom: 0;
}

