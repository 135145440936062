@use "styles/spacing";
@use "styles/theme";

.query-link-indicators {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include theme.theme {
        color: theme.get(negative);

        .query-link-indicators__icon {
            color: theme.get(negative);
        }
    }

    &__unread {
        text-align: center;
        box-shadow: 0 1px 5px rgba(black, 0.2);
        border-radius: 3px;
        padding: spacing.$spacing-xxx-small spacing.$spacing-xx-small;

        @include theme.theme {
            background-color: theme.get(negative);
            color: theme.get(background);
        }

        .query-link-indicators__unread__icon {
            margin: 0;
            font-size: 12px;

            @include theme.theme {
                color: theme.get(background);
            }
        }
    }
}
