@use "styles/spacing";
@use "styles/theme";

.navigation__chat-link {
    &-invites,
    &-messages {
        position: absolute;
        right: spacing.$spacing-xx-small;
        text-align: center;
        font-weight: bold;
        box-shadow: 0 1px 5px rgba(black, 0.2);
        border-radius: 3px;
        padding: spacing.$spacing-xxx-small spacing.$spacing-xx-small;
        margin: 0;

        @include theme.theme {
            background-color: theme.get(negative);
            color: theme.get(background);
        }
    }

    &-invites {
        top: spacing.$spacing-xx-small;
        padding: 4px;

        & &__icon {
            font-size: 8px;

            @include theme.theme {
                background-color: theme.get(negative);
                color: theme.get(background);
            }
        }
    }

    &-messages {
        bottom: spacing.$spacing-xx-small;
    }
}
