/*
  Mixin for applying ellipsis to any element
*/
.grid--is-in-box table tr + tr td {
  border-top: 1px dashed #dde4e6;
}
.grid--is-in-box th {
  background-color: white;
}
.grid--is-in-box th,
.grid--is-in-box td {
  padding: 12px 10px;
}
.grid--is-in-box tr td:first-of-type,
.grid--is-in-box tr th:first-of-type {
  padding-left: 32px;
}
.grid--is-in-box tr td:last-of-type,
.grid--is-in-box tr th:last-of-type {
  padding-right: 32px;
}
.grid--sticky-header table {
  border-collapse: separate;
}
.grid--sticky-header thead {
  position: sticky;
  top: 0;
  z-index: 10;
}
.grid:not(.grid--is-in-box) {
  position: relative;
  box-shadow: 0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}
.grid:not(.grid--is-in-box) th {
  background-color: #ecf0f1;
  line-height: 1.69rem;
}
.grid:not(.grid--is-in-box) td {
  border-top: 1px solid #f2f5f5;
}
.grid:not(.grid--is-in-box) thead tr:last-of-type th,
.grid:not(.grid--is-in-box) td {
  padding: 8px;
  vertical-align: middle;
}
.grid table {
  width: 100%;
}
.grid th {
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.1rem;
  height: 50px;
  vertical-align: middle;
  color: #7f8c8d;
  border-bottom: 1px solid #dde4e6;
}
.grid th,
.grid td {
  vertical-align: middle;
}
.grid tbody tr {
  height: 50px;
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading):not(.grid__row--new-activity):hover {
  background-color: #f5f7f7;
}
.grid tr .pages-table-button-first {
  border-left: 3px solid #f2f5f5;
}
.grid .pages-table-button + .pages-table-button {
  padding-left: 0;
}
.grid tr td.pages-table-button {
  width: 40px;
}
.grid tr th.pages-table-button {
  background-color: #f2f5f5;
}
.grid thead tr:first-of-type th:first-of-type {
  border-top-left-radius: 4px;
}
.grid thead tr:first-of-type th:last-of-type {
  border-top-right-radius: 4px;
}
.grid tfoot tr:last-of-type th:first-of-type {
  border-bottom-left-radius: 4px;
}
.grid tfoot tr:last-of-type th:last-of-type {
  border-bottom-right-radius: 4px;
}
.grid--has-buttons .grid__loading-cell {
  min-height: 30px;
}
.grid .table--has-horizontal-scroll {
  position: relative;
  background-color: white;
}
.grid .table--has-horizontal-scroll thead tr {
  position: relative;
}
.grid .table--has-horizontal-scroll thead tr th {
  z-index: 10;
}
.grid .table--has-horizontal-scroll thead tr th:not(:first-child) {
  min-width: 120px;
}
.grid .table--has-horizontal-scroll thead tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 20;
}
.grid .table--has-horizontal-scroll tbody th {
  position: sticky;
  left: 0;
  z-index: 2;
  text-align: left;
}
.grid .table--has-horizontal-scroll tbody th,
.grid .table--has-horizontal-scroll tbody td {
  white-space: nowrap;
}
.grid .table--has-horizontal-scroll tbody th:first-child,
.grid .table--has-horizontal-scroll tbody td:first-child {
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 1;
}
.grid .table--has-horizontal-scroll tbody tr:hover {
  background-color: #f5f7f7;
}
.grid .table--has-horizontal-scroll tbody tr:hover > td:first-child {
  background-color: #f5f7f7;
}

